import React, { FC, useState, ReactNode, useEffect, useMemo, useCallback } from 'react';
import { Redirect, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'component/core/i18n';
import { useClassnames } from 'hook/use-classnames';

import UI from 'component/ui';
import PasswordSet from 'component/password-set';
import Loader from 'component/loader';
import IconError from 'component/icon/error';
import IconSuccess from 'component/icon/apply';
import { IStore } from 'store/reducers/types/reducers';
import { IError } from 'component/form/types';

import { set, reset } from 'store/reducers/user/actions';
import { key as keyUser } from 'store/reducers/user/reducer';

import style from './index.pcss';
import api from 'src/api';
import { ConfirmPasswordCreateData } from 'src/api/password/types';

const ResetConfirmPassword: FC = () => {
    const cn = useClassnames(style);
    const dispatch = useDispatch();
    const { uid, token } = useParams<{ uid: string; token: string }>();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [checkPending, setCheckPending] = useState<boolean>(true);
    const [tokenValidity, setTokenValidity] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [passwordChangeStatus, setPasswordChangeStatus] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<IError> | null>(null);
    const [error, setError] = useState<string | null>(null);

    const _requestCheckToken = useCallback(() => {
        setCheckPending(true);

        api.password.checkPasswordTokenCreate(uid, token)
            .then(() => {
                setCheckPending(false);
                setTokenValidity(true);
            })
            .catch((errorData) => {
                console.error(errorData);

                setCheckPending(false);
                setTokenValidity(false);
            });

        return true;
    }, []);

    useEffect(() => {
        _requestCheckToken();
    }, []);

    const onSubmit = useCallback((new_password: string) => {
        setPending(true);

        const data: ConfirmPasswordCreateData = {
            uid,
            token,
            password: new_password
        };

        api.password.confirmPasswordCreate(data)
            .then(() => {
                setPasswordChangeStatus(true);

                api.accounts.getAccountRetrieve()
                    .then((payload) => {
                        setPending(false);
                        dispatch(set(payload.data));
                    })
                    .catch((errorData) => {
                        setPending(false);

                        setErrors(errorData.response.data);
                        if (errorData.response.data[0]) {
                            setError(errorData.response.data[0]);
                        } else {
                            setError(errorData.response.detail);
                        }

                        dispatch(reset());
                    });
            })
            .catch((errorData) => {
                console.error(errorData);

                setPending(false);

                setErrors(errorData.response.data);
                if (errorData.response.data[0]) {
                    setError(errorData.response.data[0]);
                } else {
                    setError(errorData.response.detail);
                }
            });
    }, []);

    const elContent = (): ReactNode => {
        if(checkPending && !tokenValidity) {
            return <Loader />;
        }

        if(!checkPending && !tokenValidity) {
            return (
                <div className={cn('reset-password__text-block')}>
                    <IconError className={cn('reset-password__text-block-icon', 'reset-password__text-block-icon_red')} />
                    <h2 className={cn('reset-password__text-block-title')}>{i18n.t('route.confirm-password.wrong_token.title')}</h2>
                    <Link
                        to="/password/reset"
                        className={cn('reset-password__text-block-text', 'reset-password__text-block-link')}
                    >
                        {i18n.t('route.confirm-password.wrong_token.text')}
                    </Link>
                </div>
            );
        }

        if(passwordChangeStatus) {
            return (
                <div className={cn('reset-password__text-block')}>
                    <IconSuccess className={cn('reset-password__text-block-icon', 'reset-password__text-block-icon_green')} />
                    <h2 className={cn('reset-password__text-block-title')}>{i18n.t('route.confirm-password.success.title')}</h2>
                </div>
            );
        }

        return <PasswordSet pending={pending} error={error} errors={errors} onSubmit={onSubmit} />;
    };

    if(isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <UI.Main className={cn('reset-password__ui-main')}>
            <Helmet
                title={i18n.t('helmet.title.reset-password')}
                meta={[{
                    name   : 'document-state',
                    content: 'static'
                }]}
            />

            <UI.Box padding={true} className={cn('reset-password__ui-box')}>
                {elContent()}
            </UI.Box>
        </UI.Main>
    );
};

export default ResetConfirmPassword;
