import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { ConfirmPasswordCreateData, PasswordAPIType } from './types';

const checkPasswordTokenCreate = (
    uid: string,
    token: string
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/password/check-token/', {
        uid,
        token
    });
};

const confirmPasswordCreate = (
    data: ConfirmPasswordCreateData
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/password/confirm/', {
        ...data
    });
};

const resetPasswordCreate = (email: string): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>('/api/v2/password/reset/', {
        email
    });
};
export default {
    checkPasswordTokenCreate,
    confirmPasswordCreate,
    resetPasswordCreate
} as PasswordAPIType;
